<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <!-- <v-card-title class="elevation-0">
          <span class="itemHeading">projects List</span>
          <v-spacer></v-spacer>
         
        </v-card-title> -->
        <v-layout wrap justify-start>
          <v-flex xs12 pa-4>
            <v-layout wrap>
              <v-flex align-self-center text-left>
                <span style="font-family: poppinssemibold"
                  >PROJECT VIEW - {{ $route.query.name }} </span
                >
              </v-flex>
              <v-flex
                xl3
                lg3
                md3
                sm3
                xs12
                pb-2
                pl-6
                text-right
                v-if="userType == 'SuperAdmin'"
              >
                <v-dialog
                  :retain-focus="false"
                  v-model="dialog"
                  max-width="50%"
                  :key="dialog"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="font-family: poppinsmedium"
                      block
                      small
                      dark
                      class="rounded-xl text-none"
                      color="#5F4B8BFF"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-pencil-plus-outline</v-icon>&nbsp; Add
                      Project Target
                    </v-btn>
                  </template>
                  <v-card>
                    <v-form v-model="addcat" ref="addcat">
                      <v-layout wrap>
                        <v-flex xs12 style="background-color: #5f4b8bff">
                          <span
                            style="
                              color: #ffffff;
                              font-size: 20px;
                              font-family: poppinsbold;
                              padding: 2px;
                            "
                            class="RB"
                            >ADD PROJECT TARGET</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout wrap pa-12 justify-center fill-height>
                        <v-flex xs12 align-self-center text-left>
                          <span>Total target (INR)*</span>
                          <br />
                          <v-text-field
                            v-model="name"
                            placeholder="Total target"
                            :rules="[rules.required]"
                            required
                            outlined
                            hide-details
                            dense
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 align-self-center pt-3 text-left>
                          <span>Financial Year*</span>
                          <br />
                          <v-select
                            :items="yearList"
                            v-model="myYear"
                            placeholder="Finalcial Year"
                            :rules="[rules.required]"
                            required
                            outlined
                            item-text="fyear"
                            item-value="_id"
                            hide-details
                            dense
                          ></v-select>
                        </v-flex>
                        <v-flex
                          xs12
                          md6
                          pr-md-1
                          align-self-center
                          text-left
                          pt-3
                        >
                          <span>Starting Date</span>
                          <br />
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="startingDate"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                v-model="startingDate"
                                :rules="[rules.required]"
                                label="Select Date"
                                append-icon="mdi-calendar"
                                dense
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="startingDate"
                              no-title
                              scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="menu = false">
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(startingDate)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex
                          xs12
                          md6
                          pl-md-1
                          align-self-center
                          text-left
                          pt-3
                        >
                          <span>Ending Date</span>
                          <br />
                          <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            :return-value.sync="endingDate"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :rules="[rules.required]"
                                v-model="endingDate"
                                label="Select Date"
                                append-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="endingDate"
                              no-title
                              scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="menu1 = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.menu1.save(endingDate)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                      <!-- </v-card-text> -->
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#ED2B33FF" text @click="dialogclose()">
                          Close
                        </v-btn>
                        <v-btn
                          color="#0063B2FF"
                          text
                          :disabled="!addcat"
                          @click="addProjectTarget()"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-flex>
            </v-layout>
            <v-progress-linear
              :color="appColor"
              rounded
              value="100"
              height="2px"
            ></v-progress-linear>
          </v-flex>
          <v-flex xs12 px-4 pb-3>
            <v-card flat class="small" pa-3 style="font-family: poppinsmedium">
              <v-layout wrap justify-start text-left>
                <v-flex xs12>
                  <v-card color="gray">
                    <v-layout wrap pa-3>
                      <v-flex xs12 md6 py-3>
                        <router-link
                          :to="
                            '/projectView?id=' +
                            project._id +
                            '&name=' +
                            project.projectName
                          "
                        >
                          <v-layout wrap pr-1>
                            <v-flex xs12 pb-2 class="xlarge">
                              <span style="font-family: poppinsbold">{{
                                project.projectName
                              }}</span>
                            </v-flex>
                            <v-flex xs12>
                              <v-layout wrap>
                                <v-flex xs4>
                                  <span> <b> Project Head </b></span>
                                </v-flex>
                                <v-flex xs8 v-if="project.projectHead">
                                  <span>{{ project.projectHead.name }}</span>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap py-1>
                                <v-flex xs4>
                                  <span><b>Location </b></span>
                                </v-flex>
                                <v-flex xs8>
                                  <span>{{ project.location }}</span>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap pb-1>
                                <v-flex xs4>
                                  <span> <b>Donors </b></span>
                                </v-flex>
                                <v-flex xs8>
                                  <span v-for="(item, i) in orgnames" :key="i">
                                    {{ item }},</span
                                  >
                                </v-flex>
                              </v-layout>
                              <v-layout wrap pb-1>
                                <v-flex xs4>
                                  <span> <b>WTI Team </b></span>
                                </v-flex>
                                <v-flex xs8>
                                  <span v-for="(item, i) in employees" :key="i"
                                    >{{ item }} ,</span
                                  >
                                  &nbsp;
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </router-link>
                      </v-flex>

                      <v-divider vertical> </v-divider>

                      <v-flex xs12 md6 pl-md-3 pl-0 py-0 py-md-3>
                        <router-link
                          :to="
                            '/projectView?id=' +
                            project._id +
                            '&name=' +
                            project.projectName
                          "
                        >
                          <v-layout wrap>
                            <v-flex xs4>
                              <span> <b>Project Total Split </b></span>
                            </v-flex>
                            <v-flex xs8 v-if="project.targetdetails">
                              <template v-if="project.targetdetails.length > 0">
                                <span
                                  v-for="(item2, j) in project.targetdetails"
                                  :key="j"
                                >
                                  {{ item2.fyear.fyear }} ->
                                  {{ item2.totalTarget }} (INR) <br
                                /></span>
                              </template>
                              <template v-else>
                                <span style="color: red">
                                  No Split Assigned...!
                                </span>
                              </template>
                            </v-flex>
                          </v-layout>

                          <v-layout wrap pt-2>
                            <v-flex xs4>
                              <span> <b>Description </b></span>
                            </v-flex>
                            <v-flex xs8>
                              {{ project.description }}
                            </v-flex>
                          </v-layout>
                        </router-link>
                      </v-flex>

                      <v-flex xs12 pb-2>
                        <v-divider> </v-divider>
                      </v-flex>
                      <template v-if="project.targetdetails">
                      <v-flex xs12 v-if="project.targetdetails.length > 0" pb-2>
                        <v-card>
                          <v-layout wrap class="pa-1">
                            <v-flex xs12 md3 py-2>
                              <v-select
                                outlined
                                hide-details=""
                                clearable
                                dense
                                label="Select Year"
                                :items="filterYearList"
                                v-model="SelectedYear"
                                item-text="fyear"
                                item-value="_id"
                              >
                              </v-select>
                            </v-flex>
                            <v-flex xs12 md3 py-2> </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                      </template>

                      <v-flex xs12>
                        <v-layout 
                          wrap
                          style="background-color: grey"
                          pa-2
                          v-for="(item, i) in project.targetdetails"
                          :key="i"
                          :style="
                            i % 2 == 0
                              ? 'background-color: 	 #ffe6e6'
                              : 'background-color:  #e6e6e6'
                          "
                        >
                          <v-flex xs12 md6 align-self-center >
                            <v-layout wrap>
                              <v-flex
                                xs12
                                align-self-center
                                style="color: green"
                                class="medium2"
                              >
                                <b>
                                  {{ item.totalTarget }} (INR) -> &nbsp;{{
                                    item.fyear.fyear
                                  }}</b
                                >
                              </v-flex>
                              <!-- <v-flex xs12>
                                <span>Amount Achived</span> &nbsp;- &nbsp;<span
                                  >{{ item.targetachieved }}</span
                                >
                              </v-flex> -->
                              <v-flex xs12>
                                <span>Remaining Employee Split </span> -
                                <span>
                                  <b>
                                    {{ item.maintargetbalance }} (INR)</b
                                  ></span
                                >
                              </v-flex>
                              <v-flex xs12>
                                <span>Target Deadline </span> &nbsp;
                                <span
                                  >From : {{ item.fromTime.slice(0, 10) }} &nbsp;&nbsp;&nbsp; to :
                                  {{ item.toTime.slice(0, 10) }}</span
                                >
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 md6 class="pt-2">
                          
                            <template v-if="SplitCharts[i] != null">
                              <apexchart
                                v-if="checkFlag == false"
                                type="pie"
                                width="260"
                                :options="SplitCharts[i].chartOptions"
                                :series="SplitCharts[i].series"
                              ></apexchart>
                            </template>
                          </v-flex>

                          <v-flex xs12 py-4 style="border-bottom:3px dashed white"></v-flex>
                          <v-flex xs12 pl-3 pt-5>
                            <v-layout wrap pb-1>
                              <v-flex xs8 pb-1>
                                <span> <b> Employee Split </b></span>
                              </v-flex>

                              <v-flex
                                xs4
                                text-right
                                pr-2
                                v-if="userType == 'SuperAdmin'"
                              >
                                <v-dialog
                                  :retain-focus="false"
                                  v-model="targetDialog"
                                  max-width="50%"
                                  :key="targetDialog"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      x-small
                                      style="text-transform: none"
                                      dark
                                      class="rounded-xl text-none"
                                      color="#5F4B8BFF"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="A = item"
                                    >
                                      Add New Employee 
                                    </v-btn>
                                  </template>
                                  <v-card style="font-family: poppinsregular">
                                    <v-form>
                                      <v-layout wrap>
                                        <v-flex
                                          xs12
                                          style="background-color: #5f4b8bff"
                                        >
                                          <span
                                            style="
                                              color: #ffffff;
                                              font-size: 20px;
                                              font-family: poppinsbold;
                                              padding: 2px;
                                            "
                                            class="RB"
                                            >Add New Employee </span
                                          >
                                        </v-flex>
                                      </v-layout>
                                      <v-layout
                                        wrap
                                        pa-8
                                        justify-center
                                        fill-height
                                      >
                                        <v-flex xs12 text-right>
                                          <span>Total Project Target :</span>
                                          <span
                                            style="font-family: poppinsbold"
                                            v-if="A != null"
                                          >

                                            Rs. {{ A.targettobeachieved }}
                                          </span>
                                          <br />
                                          <span>Remaining Employee Split  :</span>
                                          <span
                                            style="font-family: poppinsbold"
                                            v-if="A != null"
                                          >
                                            Rs. {{ A.maintargetbalance }}
                                          </span>
                                          <br />
                                        
                                        </v-flex>
                                        <v-flex xs12 text-left py-2>
                                          <v-divider></v-divider>
                                        </v-flex>
                                        <v-flex
                                          xs12
                                          sm6
                                          align-self-center
                                          text-left
                                          pa-2
                                        >
                                          <span>Select Employee *</span>
                                          <br />
                                          <v-autocomplete
                                            item-text="name"
                                            item-value="_id"
                                            :items="employeelist"
                                            v-model="selectedEmployee"
                                            placeholder="Enmployee Name"
                                            :rules="[rules.required]"
                                            required
                                            outlined
                                            hide-details
                                            dense
                                          ></v-autocomplete>
                                        </v-flex>

                                        <v-flex
                                          xs12
                                          sm6
                                          align-self-center
                                          text-left
                                          pa-2
                                        >
                                          <span>Employee Split Amount*</span>
                                          <br />
                                          <v-text-field
                                            v-model="EmployeeSplitAmount"
                                            placeholder="INR"
                                            :rules="[rules.required]"
                                            required
                                            outlined
                                            hide-details
                                            dense
                                          ></v-text-field>
                                        </v-flex>

                                        <v-flex
                                          xs12
                                          align-self-center
                                          text-right
                                        >
                                          <v-btn
                                            color="#CE4A7EFF"
                                            style="font-family: poppinsmedium"
                                            small
                                            tile
                                            dark
                                            class="rounded-xl"
                                            @click="EmployeeSplit(item)"
                                          >
                                            <span
                                              style="color: #ffffff !important"
                                              >Submit</span
                                            ></v-btn
                                          >
                                        </v-flex>
                                      </v-layout>
                                    </v-form>
                                  </v-card>
                                </v-dialog>
                              </v-flex>
                              <v-flex xs12>

                               <template v-if="item.employeesplitdetails.length > 0">
                                <v-layout
                                  py-2
                                  pr-2
                                  wrap
                                  v-for="(
                                    item2, j
                                  ) in item.employeesplitdetails"
                                  :key="j"
                                >
                                  <v-flex xs12>
                                    <!-- <v-card> -->
                                    <v-layout wrap
                                    >

                                      <v-flex xs12 md3 align-self-center>
                                        <v-layout wrap pr-lg-3 pr-1>
                                          <v-flex xs4><span>Employee Name</span></v-flex>
                                          <v-flex xs8 text-right>

                                            <router-link :to="'userView?id=' + item2.empId._id" >
                                          <b style="color:#4775d1"> 
                                            
                                            {{ item2.empId.name }} </b>  
                                            </router-link>
                                          </v-flex>
<v-flex xs4 pt-3>
<span>Total Split Amount</span>

</v-flex>

                                          <v-flex pt-3 xs8 text-right
                                            >-
                                            <b>{{ item2.amount }} (INR) </b>
                                          </v-flex>
                                          <v-flex xs6 py-1>
                                            Amount Received

                                            
                                          </v-flex>
                                          <v-flex py-1 xs6 text-right
                                            >-
                                            <b
                                              >{{ item.AmountReceived }}
                                              (INR)
                                            </b>
                                          </v-flex>
                                          <v-flex xs6 pt-2>
                                            Committed and Confirmed 
                                          </v-flex>
                                          <v-flex pt-2  xs6 text-right
                                            >-
                                            <b
                                              >{{ item.CommittedandConfirmed }}
                                              (INR)
                                            </b>
                                          </v-flex>
                                          <v-flex xs6 pt-2>
                                            Committed but not Confirmed

                                          </v-flex>
                                          <v-flex xs6 pt-2 text-right
                                            >-
                                            <b>
                                              {{ item.CommittedbutnotConfirmed }}
                                              (INR)
                                            </b>
                                          </v-flex>
                                          <!-- <v-flex xs6 pt-1>
                                           Extra received
                                          </v-flex>
                                          <v-flex pt-1 xs6 text-right
                                            >-
                                            <b>
                                            {{item2.extraReceivedAmount}}
                                              (INR)
                                            </b>
                                          </v-flex> -->
                                        </v-layout>

                                        <v-layout wrap>
                                          <v-flex xs12 pt-1 text-left  v-if="userType == 'SuperAdmin'">
                                            <v-dialog
                                              :retain-focus="false"
                                              v-model="AddNewOrgtDialog"
                                              max-width="50%"
                                              :key="AddNewOrgtDialog"
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                 
                                                  style="
                                                    font-family: poppinsmedium;
                                                  "
                                                  x-small
                                                  dark
                                                  class="rounded-xl text-none"
                                                  color="#5F4B8BFF"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  @click="
                                                    (B = item2),
                                                      CalculateBalance(item2)
                                                  "
                                                >
                                                  <!-- @click="A = item2" -->

                                                  Add New Org
                                                </v-btn>
                                              </template>
                                              <v-card
                                                style="
                                                  font-family: poppinsregular;
                                                "
                                              >
                                                <v-form>
                                                  <v-layout wrap>
                                                    <v-flex
                                                      xs12
                                                      style="
                                                        background-color: #5f4b8bff;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          color: #ffffff;
                                                          font-size: 20px;
                                                          font-family: poppinsbold;
                                                          padding: 2px;
                                                        "
                                                        class="RB"
                                                        >Add New Org</span
                                                      >
                                                    </v-flex>
                                                  </v-layout>
                                                  <v-layout
                                                    wrap
                                                    pa-8
                                                    justify-center
                                                    fill-height
                                                  >
                                                    <v-flex xs12 text-right>
                                                      <!-- {{B}} -->
                                                      <!-- {{totalOrgAmt}}... -->
                                                      <span
                                                        >Employee Split Amount
                                                        :</span
                                                      >
                                                      <span
                                                        style="
                                                          font-family: poppinsbold;
                                                        "
                                                        v-if="B != null"
                                                      >
                                                        Rs.
                                                        {{ B.amount }}
                                                      </span>
                                                      <br />
                                                      <span
                                                        >Remaining Organisation
                                                        Split :</span
                                                      >
                                                      <span
                                                        style="
                                                          font-family: poppinsbold;
                                                        "
                                                        v-if="B != null"
                                                      >
                                                        Rs.
                                                        {{
                                                          B.amount - totalOrgAmt
                                                        }}
                                                      </span>
                                                      <br />
                                                      <!-- <span>
                                                        Employees already
                                                        included :</span
                                                      >
                                                      <span
                                                        style="
                                                          font-family: poppinsbold;
                                                        "
                                                        v-if="A != null"
                                                      >
                                                        {{ employeeCount }}
                                                      </span> -->
                                                      <br />
                                                    </v-flex>
                                                    <v-flex xs12 text-left py-2>
                                                      <v-divider></v-divider>
                                                    </v-flex>
                                                    <v-flex
                                                      xs12
                                                      sm6
                                                      align-self-center
                                                      text-left
                                                      pa-2
                                                    >
                                                      <span
                                                        >Select
                                                        Organisation*</span
                                                      >
                                                      <br />
                                                      <v-autocomplete
                                                        item-text="name"
                                                        item-value="_id"
                                                        :items="orgList"
                                                        v-model="seletedORG"
                                                        placeholder="ORG Name"
                                                        :rules="[
                                                          rules.required,
                                                        ]"
                                                        required
                                                        outlined
                                                        hide-details
                                                        dense
                                                      ></v-autocomplete>
                                                    </v-flex>

                                                    <v-flex
                                                      xs12
                                                      sm6
                                                      align-self-center
                                                      text-left
                                                      pa-2
                                                    >
                                                      <span
                                                        >Organisation Split
                                                        Amount*</span
                                                      >
                                                      <br />
                                                      <v-text-field
                                                        v-model="ORGSplitAmount"
                                                        placeholder="INR"
                                                        :rules="[
                                                          rules.required,
                                                        ]"
                                                        required
                                                        outlined
                                                        hide-details
                                                        dense
                                                      ></v-text-field>
                                                    </v-flex>

                                                    <v-flex
                                                      xs12
                                                      align-self-center
                                                      text-right
                                                    >
                                                      <v-btn
                                                        color="#CE4A7EFF"
                                                        style="
                                                          font-family: poppinsmedium;
                                                        "
                                                        small
                                                        tile
                                                        dark
                                                        class="rounded-xl"
                                                        @click="
                                                          OrganisationSplit()
                                                        "
                                                      >
                                                        <span
                                                          style="
                                                            color: #ffffff !important;
                                                          "
                                                          >Submit</span
                                                        ></v-btn
                                                      >
                                                    </v-flex>
                                                  </v-layout>
                                                </v-form>
                                              </v-card>
                                            </v-dialog>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>

                                      <v-flex xs12 md9>
                                        <template
                                          v-if="item2.orgdetails.length > 0"
                                        >
                                          <v-simple-table dense>
                                            <template v-slot:default>
                                              <thead>
                                                <tr>
                                                  <th class="text-left">Org</th>
                                                  <th class="text-left">
                                                    Split amount (₹)
                                                  </th>
                                                  <th class="text-left">
                                                    Amount    Received (₹)
                                                  </th>
                                                  <th class="text-left">
                                                    Committed and Confirmed  (₹)
                                                  </th>
                                                  <th class="text-left">
                                                    Committed but not Confirmed (₹)
                                                  </th>
                                                   <th class="text-left">
                                                    Amount    To be raised (₹)
                                                  </th>
                                                  <th class="text-left"  v-if="userType == 'SuperAdmin'">
                                                    Action
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr
                                                  v-for="(
                                                    item3, k
                                                  ) in item2.orgdetails"
                                                  :key="k"
                                                >
                                                  <td style="font-size: 12px">
                                                    <router-link
                                                      :to="
                                                        'SplitDetaildView?id=' +
                                                        item3._id +
                                                        '&name=' +
                                                        item3.orgId.name
                                                      "
                                                    >
                                                      <span style="color: blue">
                                                        {{ item3.orgId.name }}
                                                      </span>
                                                    </router-link>
                                                  </td>
                                                  <td style="font-size: 12px">
                                                    {{ item3.amount }}
                                                  </td>
                                                  <td style="font-size: 12px">
                                                    <!-- {{ item3.alreadyraised }} -->
                                                    {{item3.amountAchievedAmount}}
                                                  </td>
                                                  <td style="font-size: 12px">
                                                    {{ item3.committedAmount }}
                                                  </td>
                                                  <td style="font-size: 12px">
                                                    {{
                                                      item3.notconfirmedAmount
                                                    }}
                                                  </td>
                                                  <td  style="font-size: 12px">
                                                    {{
                                                      item3.toberaisedAmount
                                                    }} 
                                                  </td>
                                                  <td style="font-size: 12px" v-if="userType == 'SuperAdmin'">
                                                    <v-btn
                                                      x-small
                                                      @click="
                                                        (OpenOrgEntryDialog = true),
                                                          (OrgEntryData = item3)
                                                      "
                                                    >
                                                      add 
                                                      <v-icon x-small
                                                        >mdi-plus</v-icon
                                                      >
                                                    </v-btn>
                                                  </td>
                                                </tr>
                                              </tbody>
                                             <span></span>
                                            </template>
                                          </v-simple-table>
                                        </template>

                                        <template v-else>
                                          <v-layout
                                            wrap
                                            justify-center
                                            text-center
                                            fill-height
                                          >
                                            <v-flex xs12 align-self-center>
                                              <span style="color: red">
                                                No Split Assigned yet...!
                                              </span>
                                            </v-flex>
                                          </v-layout>
                                        </template>
                                      </v-flex>
                                      <v-flex xs12 py-1>
                                        <v-divider> </v-divider>
                                      </v-flex>
                                    </v-layout>
                                    <!-- </v-card> -->
                                  </v-flex>
                                </v-layout>
                               </template>
                                    <template v-else>

                                      <v-layout wrap>
                                        <v-flex xs12 text-center>
                                                            <span style="color:red"> No Employee Split Yet...!</span>


                                        </v-flex>
                                      </v-layout>
                                    </template>



                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <!-- <v-divider vertical> </v-divider> -->

                          <v-flex xs12 v-if="userType == 'SuperAdmin'">
                            <v-layout wrap justify-end>
                              <v-flex xs12 sm4 lg2 pa-2 text-right> </v-flex>
                              <v-flex xs6 sm4 lg2 pa-2 text-right>
                                <v-btn
                                  color="#0063B2FF"
                                  style="font-family: poppinsmedium"
                                  x-small
                                  text
                                  block
                                  tile
                                  dark
                                  @click="editcat(item)"
                                  >Edit</v-btn
                                >
                              </v-flex>
                              <v-flex xs6 sm4 lg2 pa-2 text-right>
                                <v-dialog
                                  persistent
                                  v-model="item.delete"
                                  max-width="600px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      x-small
                                      style="font-family: poppinsmedium"
                                      class="mr-2"
                                      dark
                                      block
                                      text
                                      v-on="on"
                                      v-bind="attrs"
                                      color="#ED2B33FF"
                                    >
                                      Delete
                                    </v-btn>
                                  </template>
                                  <v-card>
                                    <v-card-title
                                      >Are you sure you want to delete this
                                      ProjectTarget??</v-card-title
                                    >
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="#ED2B33FF"
                                        text
                                        @click="item.delete = false"
                                        >Cancel</v-btn
                                      >
                                      <v-btn
                                        color="#0063B2FF"
                                        text
                                        @click="deleteItem(item)"
                                        >OK</v-btn
                                      >
                                      <v-spacer></v-spacer>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-flex>
                              <!-- <v-flex xs12 style="border-bottom:3px solid grey">

                      </v-flex> -->
                            </v-layout>
                          </v-flex>
                          <!-- <v-flex xs4 pl-3>
        <v-layout wrap pb-1>
          <v-flex xs12>
            <span> <b> Organisation Split </b></span>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap v-for="(item3, k) in item.orgdetails" :key="k">
              <v-flex xs4> {{item3.orgId.name}} </v-flex>
              <v-flex xs8> -{{item3.amount}} &nbsp;(INR) </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex> -->
                        </v-layout>
                        <v-layout wrap v-if="project.targetdetails == 0">
                          <v-flex xs12 text-center pt-2>
                            <span>No Data Available....!</span>
                          </v-flex>
                        </v-layout>

                        <v-layout py-1>
                          <v-flex xs12>
                            <v-divider></v-divider>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <!-- <v-flex xs12 text-right>
                        <v-layout wrap justify-end text-right>
                          <v-flex
                            xs12
                            sm4
                            lg1
                            pa-2
                            v-if="userType == 'SuperAdmin'"
                          >
                            <v-btn
                              color="#0063B2FF"
                              style="font-family: poppinsmedium"
                              block
                              outlined
                              dark
                              x-small
                              @click="editcat(item)"
                              >Edit</v-btn
                            >
                          </v-flex>
                          <v-flex
                            xs12
                            sm4
                            lg1
                            pa-2
                            v-if="userType == 'SuperAdmin'"
                          >
                            <v-dialog
                              persistent
                              v-model="item.delete"
                              max-width="600px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  x-small
                                  style="font-family: poppinsmedium"
                                  class="mr-2"
                                  dark
                                  block
                                  outlined
                                  v-on="on"
                                  v-bind="attrs"
                                  color="#ED2B33FF"
                                >
                                  Delete
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title
                                  >Are you sure you want to delete this
                                  Project?</v-card-title
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="#ED2B33FF"
                                    text
                                    @click="item.delete = false"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    color="#0063B2FF"
                                    text
                                    @click="deleteItem(item)"
                                    >OK</v-btn
                                  >
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-flex>
                        </v-layout>
                      </v-flex> -->
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>

          <template v-if="projectsTarget.length > 0">
            <v-flex
              xs12
              align-self-center
              v-for="(item, i) in projectsTarget"
              :key="i"
              fill-height
              pa-4
            >
              <v-card
                tile
                elevation="2"
                outlined
                style="
                  border-radius: 0px 0px 20px !important;
                  border-bottom: 5px solid #5f4b8bff;
                "
              >
                <v-layout wrap justify-center py-4>
                  <v-flex xs12 text-right>
                    <v-btn
                      color="green"
                      style="font-family: poppinsmedium"
                      x-small
                      text
                      :to="'/projectSplitView?id=' + item._id"
                    >
                      view
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 align-self-start text-left pa-3>
                    <v-layout wrap>
                      <v-flex xs6>
                        <span style="font-family: poppinsbold">
                          Target
                        </span></v-flex
                      >
                      <v-flex xs6
                        ><span style="font-family: poppinsmedium"
                          >{{ item.totalTarget }} (INR)</span
                        >
                      </v-flex>
                      <v-flex xs12 pb-3> <v-divider></v-divider></v-flex>
                      <v-flex xs6>
                        <span style="font-family: poppinsbold">
                          Financial Year
                        </span></v-flex
                      >
                      <v-flex xs6
                        ><span style="font-family: poppinsmedium">{{
                          item.fyear.fyear
                        }}</span></v-flex
                      >
                      <v-flex xs12 pb-3> <v-divider></v-divider></v-flex>
                      <v-flex xs6>
                        <span style="font-family: poppinsbold">
                          Target Achieved 
                        </span></v-flex
                      >
                      <v-flex xs6
                        ><span style="font-family: poppinsmedium">{{
                          item.amountAchieved
                        }}</span></v-flex
                      >
                      <v-flex xs12 pb-3> <v-divider></v-divider></v-flex>
                      <v-flex xs6>
                        <span style="font-family: poppinsbold">
                          Target Deadline
                        </span></v-flex
                      >
                      <v-flex xs6 v-if="item.fromTime && item.toTime">
                        <span style="font-family: poppinsmedium">
                          From : {{ item.fromTime.slice(0, 10) }} to :
                          {{ item.toTime.slice(0, 10) }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex xs12 pa-2 text-right v-if="userType == 'SuperAdmin'">
                    <v-dialog
                      :retain-focus="false"
                      v-model="targetDialog"
                      max-width="50%"
                      :key="targetDialog"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="font-family: poppinsmedium"
                          small
                          dark
                          class="rounded-xl text-none"
                          color="#5F4B8BFF"
                          v-bind="attrs"
                          v-on="on"
                          @click="A = projectsTarget[i]"
                        >
                          <v-icon small>mdi-pencil-plus-outline</v-icon>&nbsp;
                          Add Employee Split
                        </v-btn>
                      </template>
                      <v-card style="font-family: poppinsregular">
                        <v-form>
                          <v-layout wrap>
                            <v-flex xs12 style="background-color: #5f4b8bff">
                              <span
                                style="
                                  color: #ffffff;
                                  font-size: 20px;
                                  font-family: poppinsbold;
                                  padding: 2px;
                                "
                                class="RB"
                                >ADD TARGET</span
                              >
                            </v-flex>
                          </v-layout>
                          <v-layout wrap pa-8 justify-center fill-height>
                            <v-flex xs12 text-right>
                              <span>Total Project Target :</span>
                              <span
                                style="font-family: poppinsbold"
                                v-if="A != null"
                              >
                                Rs. {{ A.targettobeachieved }}
                              </span>
                              <br />
                              <span>Remaining Target :</span>
                              <span
                                style="font-family: poppinsbold"
                                v-if="A != null"
                              >
                                Rs. {{ balanceAmount }}
                              </span>
                              <br />
                              <span> Employees already included :</span>
                              <span
                                style="font-family: poppinsbold"
                                v-if="A != null"
                              >
                                {{ employeeCount }}
                              </span>
                              <br />
                            </v-flex>
                            <v-flex xs12 text-left py-2>
                              <v-divider></v-divider>
                            </v-flex>
                            <v-flex xs12 sm6 align-self-center text-left pa-2>
                              <span>Select Employee*</span>
                              <br />
                              <v-autocomplete
                                item-text="eid.name"
                                item-value="_id"
                                :items="employeelist"
                                v-model="selectedEmployee"
                                placeholder="Enmployee Name"
                                :rules="[rules.required]"
                                required
                                outlined
                                hide-details
                                dense
                              ></v-autocomplete>
                            </v-flex>

                            <v-flex xs12 sm6 align-self-center text-left pa-2>
                              <span>Employee Split Amount*</span>
                              <br />
                              <v-text-field
                                v-model="EmployeeSplitAmount"
                                placeholder="INR"
                                :rules="[rules.required]"
                                required
                                outlined
                                hide-details
                                dense
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs12 align-self-center text-right>
                              <v-btn
                                color="#CE4A7EFF"
                                style="font-family: poppinsmedium"
                                small
                                tile
                                dark
                                class="rounded-xl"
                                @click="EmployeeSplit(item)"
                              >
                                <span style="color: #ffffff !important"
                                  >Submit</span
                                ></v-btn
                              >
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                  <!-- <v-flex xs12 pa-2>
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          color="grey"
                          class="white--text"
                        >
                          Fund Details
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Date</th>

                                  <th class="text-left">Already received</th>
                                  <th class="text-left">
                                    Committed and confirmed
                                  </th>
                                  <th class="text-left">
                                    Committed but not confirmed
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  class="text-left"
                                  v-for="(item2, i) in item.projectTargetData"
                                  :key="i"
                                >
                                  <td>{{ formatDate(item2.create_date) }}</td>
                                  <td>{{ item2.amountAchieved }}</td>
                                  <td>{{ item2.committed }}</td>
                                  <td>{{ item2.notconfirmed }}</td>
                                </tr>
                                <tr class="text-left">
                                  <td></td>
                                  <td>Total</td>
                                  <td>Total</td>
                                  <td>Total</td>
                                </tr>
                                <tr class="text-left">
                                  <td></td>
                                  <td>
                                    <b> {{ item.amountAchieved }} (INR) </b>
                                  </td>
                                  <td>
                                    <b>{{ item.committed }} (INR) </b>
                                  </td>
                                  <td>
                                    <b>{{ item.notconfirmed }} (INR) </b>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-flex> -->
                  <v-flex xs12 v-if="userType == 'SuperAdmin'">
                    <v-layout wrap justify-end>
                      <v-flex xs12 sm4 lg2 pa-2 text-right> </v-flex>
                      <v-flex xs12 sm4 lg2 pa-2 text-right>
                        <v-btn
                          color="#0063B2FF"
                          style="font-family: poppinsmedium"
                          small
                          text
                          block
                          tile
                          dark
                          @click="editcat(item)"
                          >Edit</v-btn
                        >
                      </v-flex>
                      <v-flex xs12 sm4 lg2 pa-2 text-right>
                        <v-dialog
                          persistent
                          v-model="item.delete"
                          max-width="600px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              style="font-family: poppinsmedium"
                              class="mr-2"
                              dark
                              block
                              text
                              v-on="on"
                              v-bind="attrs"
                              color="#ED2B33FF"
                            >
                              Delete
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title
                              >Are you sure you want to delete this
                              ProjectTarget?</v-card-title
                            >
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="#ED2B33FF"
                                text
                                @click="item.delete = false"
                                >Cancel</v-btn
                              >
                              <v-btn
                                color="#0063B2FF"
                                text
                                @click="deleteItem(item)"
                                >OK</v-btn
                              >
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </template>

          <!-- <v-flex xs12>
            <span style="font-family: poppinsbold" class="RB"
              >Empty List....!</span
            >
          </v-flex> -->

          <v-dialog persistent v-model="editdialog" max-width="50%">
            <v-card tile>
              <v-layout wrap>
                <v-flex xs12 style="background-color: #5f4b8bff">
                  <span
                    style="
                      color: #ffffff;
                      font-size: 20px;
                      font-family: poppinsbold;
                      padding: 2px;
                    "
                    class="RB"
                    >EDIT ORGANISATION</span
                  >
                </v-flex>
              </v-layout>
              <v-layout wrap pa-12 justify-center fill-height>
                <v-flex xs12 align-self-center text-left>
                  <span>Total target (INR)*</span>
                  <br />

                  <v-text-field
                    v-model="editingitem.totalTarget"
                    placeholder="Total target"
                    :rules="[rules.required]"
                    required
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 align-self-center pt-3 text-left>
                  <span>Financial Year*</span>
                  <br />
                  <v-select
                    :items="yearList"
                    v-model="editingitem.fyear"
                    placeholder="Finalcial Year"
                    :rules="[rules.required]"
                    required
                    outlined
                    item-text="fyear"
                    item-value="_id"
                    hide-details
                    dense
                  ></v-select>
                </v-flex>

                <v-flex xs12 md6 pr-md-1 align-self-center text-left pt-3>
                  <span>Starting Date</span>
                  <br />
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="editingitem.startingDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="editingitem.startingDate"
                        :rules="[rules.required]"
                        label="Select Date"
                        append-icon="mdi-calendar"
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editingitem.startingDate"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(editingitem.startingDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 md6 pl-md-1 align-self-center text-left pt-3>
                  <span>Ending Date</span>
                  <br />
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="editingitem.endingDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :rules="[rules.required]"
                        v-model="editingitem.endingDate"
                        label="Select Date"
                        append-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editingitem.endingDate"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu1.save(editingitem.endingDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#ED2B33FF" text @click="getdData"> Close </v-btn>
                <v-btn
                  v-if="editingitem.totalTarget"
                  color="#0063B2FF"
                  text
                  @click="edit(editingitem._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="OpenOrgEntryDialog" width="300px">
            <v-card color="black" width="300px">
              <v-layout wrap pt-5 pr-5 pl-5>
                <v-flex xs12>
                  <v-select
                    outlined
                    dark
                    dense
                    label="Types"
                    :items="values"
                    v-model="assignType"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    outlined
                    dark
                    dense
                    label="amount"
                    v-model="MyAmount"
                  >
                  </v-text-field>
                </v-flex>

                <v-flex
                  xs12
                  v-if="
                    assignType == 'already received' ||
                    assignType == 'committed and confirmed'
                  "
                >
                  <v-menu
                    ref="menu4"
                    v-model="menu4"
                    :close-on-content-click="false"
                    :return-value.sync="FundApprovedDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="FundApprovedDate"
                        :rules="[rules.required]"
                        label="Select Date"
                        append-icon="mdi-calendar"
                        dense
                        dark
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="FundApprovedDate"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu4.save(FundApprovedDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>


                <v-flex
                  xs12
                  v-if="
                    assignType == 'committed but not confirmed'
                  
                  "
                >
                  <v-menu
                    ref="menu5"
                    v-model="menu5"
                    :close-on-content-click="false"
                    :return-value.sync="notConfirmedDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="notConfirmedDate"
                     
                        label="Select Date"
                        append-icon="mdi-calendar"
                        dense
                        dark
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="notConfirmedDate"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu5.save(notConfirmedDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>


                
                <v-flex
                  xs12
                  v-if="
                    assignType == 'to be raised'
                  
                  "
                >
                  <v-menu
                    ref="menu6"
                    v-model="menu6"
                    :close-on-content-click="false"
                    :return-value.sync="toBeRaisedDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="toBeRaisedDate"
                     
                        label="Select Date"
                        append-icon="mdi-calendar"
                        dense
                        dark
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="toBeRaisedDate"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu6.save(toBeRaisedDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>

              </v-layout>
              <v-layout wrap pa-2>
                <v-flex xs12>
                  <v-btn @click="addToRaiseFund()"> save </v-btn>
                </v-flex>
              </v-layout>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      orgnames: [],
      menu4: false,
      checkFlag: false,
      assignType: null,
      MyAmount: null,
      notConfirmedDate: null,
      OrgEntryData: null,
      values: [
        "already received",
        "committed and confirmed",
        "committed but not confirmed",
         "to be raised",
      ],

      employees: [],
      targetdetails: [],
      SplitCharts: [],
      OpenOrgEntryDialog: false,
      chart: {
        series: [],
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          labels: [],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },

      //////////////////////////////////////////////
      B: null,
      ORGSplitAmount: null,
      seletedORG: null,
      orgList: [],
      AddNewOrgtDialog: false,
      project: [],
      menu: false,
      menu1: false,
      menu5: false,
      menu6: false,

      startingDate: null,
      endingDate: null,
      ///////////////////////////////
      splitFor: [
        "test1",
        "test2",
        "test3",
        "test3",
        "test5",
        "test6",
        "test7",
        "test8",
      ],
      employeeCount: null,
      balanceAmount: null,
      A: null,
      EmployeeSplitAmount: null,
      employeelist: [],
      selectedEmployee: null,
      targetDialog: false,
      myYear: null,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      projectsTarget: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      array:[],
      formDataCover: new FormData(),
      flagg: true,
      yearList: [],
      SelectedYear: null,
      filterYearList: [],
      toBeRaisedDate:"",

      totalOrgAmt: null,
    };
  },
  components: {
    // ImageComp,
  },
  mounted() {
    this.getData();
    this.getYear();
    this.getAllUsers();
    this.getOrganization();
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    SelectedYear() {
       this.SplitCharts = [];
      this.getData();
    },
  },
  computed: {
    userType() {
      return this.$store.state.userType;
    },
  },

  methods: {
    CalculateBalance(value) {
      this.totalOrgAmt = 0;
      var z = 0;
      for (let i = 0; i < value.orgdetails.length; i++) {
        z = z + value.orgdetails[i].amount;
      }
      this.totalOrgAmt = z;
    },

    addToRaiseFund() {
      let datas = {};

      datas["id"] = this.OrgEntryData._id;
      datas["type"] = this.assignType;
      datas["amount"] = this.MyAmount;

      if (this.assignType == "already received") {
        datas["alreadyraisedDate"] = this.FundApprovedDate;
      }

      if (this.assignType == "committed and confirmed") {
        datas["committedDate"] = this.FundApprovedDate;
      }
      if (this.assignType == "committed but not confirmed") {
        datas["notConfirmedDate"] = this.notConfirmedDate;
      }
      if (this.assignType == "to be raised") {
        datas["toBeRaisedDate"] = this.toBeRaisedDate;
      }
      axios({
        url: "/add/split/amount",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        // data: {
        //   id: this.OrgEntryData._id,
        //   type: this.assignType,

        // },

        data: datas,
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status) {
            this.OpenOrgEntryDialog = false;
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.getData();

            this.$router.push(
              "/SplitDetaildView?id=" +
                this.OrgEntryData._id +
                "&name=" +
                this.OrgEntryData.orgId.name
            );
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.spliofSplitDial = false;
            this.targetData = [];
            this.assignType = null;
            this.MyAmount = 0;
          }
          //  this.$router.push("/SplitDetaildView?id =" + this.splitID )
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    ////////////////////////////////////////////////////////
    OrganisationSplit(value) {
      console.log("this.B", value);
      console.log("this.B", this.B);

      {
        this.appLoading = true;
        axios({
          url: "/split/target/add",
          method: "post",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            empId: this.B.empId._id,
            orgId: this.seletedORG,
            targetId: this.B.targetid,
            amount: this.ORGSplitAmount,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              // this.userData = response.data.data;
              // item.dialogSplit = false;
              (this.AddNewOrgtDialog = false), this.getData();
            } else {
              this.showsnackbar = true;
              this.msg = response.data.msg;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err());
          });
      }
    },

    getOrganization() {
      this.appLoading = true;
      axios({
        url: "/organization/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.orgList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    formatDate(item) {
      return item.substring(0, 10);
    },
    EmployeeSplit() {
      this.appLoading = true;
      axios({
        url: "/split/target/add/employees",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          projectid: this.$route.query.id,
          amount: this.EmployeeSplitAmount,
          targetId: this.A._id,
          empId: this.selectedEmployee,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // this.userData = response.data.data;
            this.dialog = false;
            this.targetDialog = false;
            this.showsnackbar = true;
            this.msg = response.data.msg;
            // this.$router.push("/userView?id=" + this.selectedEmployee);
  

            this.getData();
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
    },

    getAllUsers() {
      this.appLoading = true;
      axios({
        url: "/superadmin/view/employeelist/fundRise",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.employeelist = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getdData() {
      this.editdialog = false;
      this.getData();
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/target/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // /fyear/getlist

    getYear() {
      this.appLoading = true;
      axios({
        url: "/fyear/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.SelectedYear = response.data.data[0]._id;
          this.yearList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/target/getlist",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          projectid: this.$route.query.id,
          fyear: this.SelectedYear,
        },
      })
        .then((response) => {

          if(response.data.status)
          {
            // this.fyear = response.data.data[0]._id;
            this.appLoading = false;
          this.SplitCharts = [];
          this.project = response.data.data;
          this.projectsTarget = response.data.data;
          this.totalData = response.data.count;
          this.balanceAmount = response.data.balanceamount;
          this.orgnames = response.data.organizations;
          this.array = response.data.targetdetails;

          this.employees = response.data.employees;
          if (response.data.data.targetdetails) {
            this.targetdetails = response.data.data.targetdetails;
          }

          if (response.data.employeedata) {
            this.employeeCount = response.data.employeedata.length;
          }

          for (let i = 0; i < response.data.data.targetdetails.length; i++) {
            this.filterYearList.push(
              JSON.parse(
                JSON.stringify(response.data.data.targetdetails[i].fyear)
              )
            );
          }

          if (response.data.data.targetdetails) {
            this.checkFlag = true;
            for (let j = 0; j < this.targetdetails.length; j++) {
              this.chart.chartOptions.labels = [];
              this.chart.series = [];

              let bal = this.targetdetails[j].maintargetbalance;

              for (
                let k = 0;
                k < this.targetdetails[j].employeesplitdetails.length;
                k++
              ) {
                this.chart.chartOptions.labels.push(
                  JSON.parse(
                    JSON.stringify(
                      this.targetdetails[j].employeesplitdetails[k].empId.name
                    )
                  )
                );

                this.chart.series.push(
                  JSON.parse(
                    JSON.stringify(
                      this.targetdetails[j].employeesplitdetails[k].amount
                    )
                  )
                );
              }

              this.chart.chartOptions.labels.push(
                JSON.parse(JSON.stringify("Remaining Split"))
              );

              this.chart.series.push(JSON.parse(JSON.stringify(bal)));

              this.SplitCharts.push(JSON.parse(JSON.stringify(this.chart)));

              bal = 0;

              // console.log(" this.SplitCharts", this.SplitCharts);
            }

            this.checkFlag = false;
          }
         
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    addProjectTarget() {
      var data = {};
      data["projectid"] = this.$route.query.id;
      data["totalTarget"] = this.name;
      data["financialyear"] = this.myYear;
      /////
      data["fromTime"] = this.startingDate;
      data["toTime"] = this.endingDate;
      // data["financialyear"] = this.myYear;

      axios({
        url: "/add/project/target",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.name = null;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
      this.editingitem.startingDate = item.fromTime.slice(0, 10);
      this.editingitem.endingDate = item.toTime.slice(0, 10);
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["totalTarget"] = this.editingitem.totalTarget;
      data["financialyear"] = this.editingitem.fyear;
      data["fromTime"] = this.editingitem.startingDate;
      data["toTime"] = this.editingitem.endingDate;

      axios({
        url: "/target/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            // if (this.coverImageFile) {
            //   this.uploadCoverImages(this.editingitem._id);
            // }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style >
.v-simple-table {
  font-size: 12px !important;
  color: red;
}
</style>